const selectors = {
  mobileNavigation: '#main-header .mobile-navigation',
  links: '#main-header .cmp-navigation__item a',
  container: '#main-header .aem-Grid',
  modal: '#main-header .modal',
  modalBody: '#main-header .modal-body',
  mobileIconRoot: '#main-header .mobile-nav-icon .icon-root',
  openSvg: '#main-header .mobile-nav-icon .icon-root svg',
  closeSvg:  '#main-header .modal-header svg',
  mobileBtn: '#main-header .mobile-nav-icon .mobile-nav-trigger',
  mobileCloseBtn: '#main-header .modal-header button',
  headerRoot: '.cmp-experiencefragment--header'
}

// create mobile menu svg
const createModalIcon = () => {

  let iconDiv = document.createElement("div");
  iconDiv.classList.add("mobile-nav-icon");
  iconDiv.innerHTML =  `<button class="mobile-nav-trigger"><span class="icon-root"></span></button>`;

  // append iconDiv to header
  document.querySelector(selectors.mobileNavigation).appendChild(iconDiv);

  // create svg
  let svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
  svg.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
  svg.setAttribute('width', '24');
  svg.setAttribute('height', '24');
  svg.setAttribute('viewbox', '0 0 24 24');
  svg.setAttribute('fill', 'none');
  svg.setAttribute('stroke', 'white');
  svg.setAttribute('stroke-width', '2');
  svg.setAttribute('stroke-linecap', 'round');
  svg.setAttribute('stroke-linejoin', 'round');
  svg.setAttributeNS("http://www.w3.org/2000/xmlns/", "xmlns:xlink", "http://www.w3.org/1999/xlink");

  let lineA = document.createElementNS('http://www.w3.org/2000/svg','line');
  lineA.setAttribute('x1', '3');
  lineA.setAttribute('y1', '12');
  lineA.setAttribute('x2', '21');
  lineA.setAttribute('y2', '12');

  let lineB = document.createElementNS('http://www.w3.org/2000/svg','line');
  lineB.setAttribute('x1', '3');
  lineB.setAttribute('y1', '6');
  lineB.setAttribute('x2', '21');
  lineB.setAttribute('y2', '6');

  let lineC = document.createElementNS('http://www.w3.org/2000/svg','line');
  lineC.setAttribute('x1', '3');
  lineC.setAttribute('y1', '18');
  lineC.setAttribute('x2', '21');
  lineC.setAttribute('y2', '18');

  // append svg to icon-root
  document.querySelector(selectors.mobileIconRoot).appendChild(svg);
  document.querySelector(selectors.openSvg).appendChild(lineA);
  document.querySelector(selectors.openSvg).appendChild(lineB);
  document.querySelector(selectors.openSvg).appendChild(lineC);
 
}

// create svg to close modal
const createXIcon = () => {
  let svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
  svg.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
  svg.setAttribute('width', '24');
  svg.setAttribute('height', '24');
  svg.setAttribute('viewbox', '0 0 24 24');
  svg.setAttribute('fill', 'none');
  svg.setAttribute('stroke', 'white');
  svg.setAttribute('stroke-width', '2');
  svg.setAttribute('stroke-linecap', 'round');
  svg.setAttribute('stroke-linejoin', 'round');
  svg.setAttributeNS("http://www.w3.org/2000/xmlns/", "xmlns:xlink", "http://www.w3.org/1999/xlink");

  let lineA = document.createElementNS('http://www.w3.org/2000/svg','line');
  lineA.setAttribute('x1', '18');
  lineA.setAttribute('y1', '6');
  lineA.setAttribute('x2', '6');
  lineA.setAttribute('y2', '18');

  let lineB = document.createElementNS('http://www.w3.org/2000/svg','line');
  lineB.setAttribute('x1', '6');
  lineB.setAttribute('y1', '6');
  lineB.setAttribute('x2', '18');
  lineB.setAttribute('y2', '18');

  // append svg to modal-header
  document.querySelector(selectors.mobileCloseBtn).appendChild(svg);
  document.querySelector(selectors.closeSvg).appendChild(lineA);
  document.querySelector(selectors.closeSvg).appendChild(lineB); 

}

// create the modal
const createMenu = () => {

  let mobileNav = document.createElement("div");
  mobileNav.classList.add('mobile-navigation');

  let modal = document.createElement("div");
  modal.classList.add('modal');

  modal.innerHTML = `<div class="modal-content">` +
    `<header class="modal-header"><button class="modal-close-btn"></button><span class="modal-title">Main Menu</span></header>` +
    `<div class="modal-body"></div></div>`;               
  
  mobileNav.appendChild(modal);
  document.querySelector(selectors.container).appendChild(mobileNav);

  // add svgs
  createModalIcon();
  createXIcon();

}

// grab links from desktop nav and populate them into mobile nav
const populateMenu = () => {
  const links = document.querySelectorAll(selectors.links);
  const copy = [...links];

  copy.forEach((link) => {

    let tempList = document.createElement('li');
    tempList.classList.add('cmp-navigation__item');

    let tempLink = document.createElement("a");
    tempLink.setAttribute('href', link?.href);
    tempLink.innerHTML = link?.text;

    tempList.appendChild(tempLink);
    document.querySelector(selectors.modalBody).appendChild(tempList);
  })
}

const setListeners = () => {
  // transparent header at top of page
  document.body.addEventListener('scroll', () => {
    const header = document.querySelector(selectors.headerRoot);
    if (header == null) {
      return;
    }
    const color = header.style.backgroundColor;
    if (document.body.scrollTop == 0) {
      header.style.backgroundColor = 'transparent';
    }
    else if (color == 'transparent' || color == '') {
      header.style.backgroundColor = 'black';
    }
  });

  // when icon is clicked, show modal & set close listener
  document.querySelector(selectors.mobileBtn).addEventListener("click", () => {

    document.querySelector(selectors.modal).style.visibility = 'visible';
    document.querySelector(selectors.modal).style.display = 'flex';

    // set close listener
    document.querySelector(selectors.mobileCloseBtn).addEventListener('click', () => {
      document.querySelector(selectors.modal).style.visibility = 'hidden';
      document.querySelector(selectors.modal).style.display = 'none';
    });
  });
}

const initializeHeaders = () => {
  createMenu();
  populateMenu();
  setListeners();
}

/* initialize mobile header on page load */
(function(document) {
  function onDocumentReady() {
      // Initialize mobile nav
      initializeHeaders();
  }
  if (document.readyState !== 'loading') {
      onDocumentReady();
  } else {
      document.addEventListener('DOMContentLoaded', onDocumentReady);
  }
})(window.document);
